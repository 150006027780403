<template>
  <div class="m-auto h-screen flex justify-center items-center">
    <div class="w-1/4 rounded rounded-lg overflow-hidden bg-white shadow shadow-md">
      <div class="bg-black text-white p-5 font-bold">
        <h1>Upload Voice Over</h1>
      </div>
      <div class="p-12">
        <Textbox label="Your name (in upwork)" class="mb-4" v-model="voice.name"/>

        <Info img="images/code.png">
          <Textbox label="Country Code of voice over" class="mb-4" v-model="voice.geo"/>
        </Info>

        <Info img="images/product.png">
          <Textbox label="Product" class="mb-4" v-model="voice.product"/>
        </Info>

        <Uploadbox
            name="voice"
            label="Upload mp3 file"
            ref="uploader"
            :allowed="['mp3']"
            :endpoint="config.baseUrl + '/upload'"
            @file="fileName => uploaded(fileName)"
            class="mb-3 cursor-pointer"/>

        <div v-if="data.message" class="bg-green text-green-dark p-2 font-bold rounded-md mb-3 text-xs w-full">
          {{ data.message }}
        </div>

        <Button v-if="!isUploaded" class="opacity-50" label="Upload Voice Over"/>

        <HttpPost v-if="isUploaded" :url="config.baseUrl + '/voices/create'" :body="voice" @fetch="fetch => data = fetch.data">
          <div v-if="data.validation">
            <span class="text-red text-xs font-bold" v-for="(error, index) in data.validation" :key="index">{{
                error
              }}</span>
          </div>
          <Button v-bind:class="{'mt-3': data.validation }" label="Upload Voice Over"/>
        </HttpPost>
      </div>
    </div>
  </div>
</template>
<script>
import {Vue, Component, Watch} from 'vue-property-decorator'
import Info from './Info';
import Card from '@/mave-ui/components/Card'
import HttpPost from "@/mave-ui/components/logic/HttpPost";
import Uploadbox from "@/mave-ui/components/form/Uploadbox";
import Textbox from "@/mave-ui/components/form/Textbox";
import Button from "@/mave-ui/components/form/Button";

@Component({
  components: {Textbox, HttpPost, Card, Uploadbox, Button, Info}
})
export default class Uploader extends Vue {
  data = ''
  voice = {
    name: '',
    geo: '',
    product: '',
    file: ''
  }
  isUploaded = false

  @Watch('data', {deep: true})
  handleData() {
    this.voice = {
      name: '',
      geo: '',
      product: '',
      file: ''
    }
    if (this.data.isFetching === false) {
      this.$refs.uploader.uploadSubmitted()
      this.$refs.uploader.$forceUpdate()
    }
  }

  uploaded(fileName) {
    this.voice.file = fileName
    this.isUploaded = true
    this.$refs.uploader.uploadComplete()
    this.$forceUpdate()
  }
}
</script>
