











import {Vue, Component, Prop} from 'vue-property-decorator'

@Component
export default class Info extends Vue {
  @Prop() img!: string;

  isOpen = false
}
