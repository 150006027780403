





















import { Vue, Component, Prop } from 'vue-property-decorator'
import VueUploadComponent from 'vue-upload-component'
import LoaderIcon from '@/mave-ui/components/svg/LoaderIcon.vue'

@Component({
  components: { LoaderIcon, FileUpload: VueUploadComponent }
})
export default class Uploadbox extends Vue {
  @Prop() label!: string
  @Prop() name!: string
  @Prop() endpoint!: string
  @Prop() allowed!: Array<string>

  files = []
  showError = false
  description = this.label
  fileName = ''
  isUploading = false

  inputFile (newFile: any, oldFile: any) {
    if (newFile && oldFile && !newFile.active && oldFile.active) {
      this.$emit('file', newFile.response.name)
    }
  }

  /**
   * Pretreatment
   * @return undefined
   * @param newFile
   * @param oldFile
   * @param prevent
   */
  inputFilter (newFile: any, oldFile: any, prevent: Function) {
    if (newFile && !oldFile) {
      // Filter non-image file
      if (!/\.(mp3)$/i.test(newFile.name)) {
        this.showError = true
        return prevent()
      }
    }

    // Create a blob field
    newFile.blob = ''
    const URL = window.URL || window.webkitURL
    if (URL && URL.createObjectURL) {
      newFile.blob = URL.createObjectURL(newFile.file)
    }

    this.description = newFile.name + ' is uploading...'
    this.isUploading = true
    this.fileName = newFile.name
    this.showError = false
  }

  uploadComplete () {
    this.description = this.fileName + ' is uploaded!'
    this.isUploading = false
  }

  uploadSubmitted () {
    this.description = this.label
  }
}
