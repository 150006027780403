<template>
  <div class="py-26">
    <Uploader />
  </div>
</template>
<script>
import {Vue, Component} from "vue-property-decorator";
import Uploader from "@/components/Uploader";

@Component({
  components: {Uploader}
})
export default class Upload extends Vue {

}
</script>
